import React, { forwardRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { countries, projecttypes } from "./constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Parse from "parse";

interface FormsProps {
  siteKey?: string;
}

const Forms = forwardRef<HTMLDivElement, FormsProps>(
  (
    { siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "DUMMY_SITE_KEY" },
    ref
  ) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [project, setProject] = useState("");
    const [country, setCountry] = useState("");
    const [message, setMessage] = useState("");

    const handleFormSubmit = async (event: React.FormEvent) => {
      event.preventDefault();
      const data = {
        name: name,
        email: email,
        phone: phone,
        project: project,
        country: country,
        message: message,
      };
      try {
        const Quotation = Parse.Object.extend("Quotation");
        const quotation = new Quotation();

        quotation.set("name", name);
        quotation.set("email", email);
        quotation.set("phone", phone);
        quotation.set("project", project);
        quotation.set("country", country);
        quotation.set("message", message);

        const savedQuotation = await quotation.save(null, {
          useMasterKey: true,
        });

        if (savedQuotation) {
          toast.success(
            "Submission received successfully. We will get back to you shortly!",
            {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          setName("");
          setEmail("");
          setPhone("");
          setProject("");
          setCountry("");
          setMessage("");
        } else {
          toast.error(
            "Submission unsuccessful. Please try again, and if the problem persists, please reach out to us at info@tactech.tech",
            {
              position: "bottom-center",
              autoClose: 8000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      } catch (e) {
        toast.error(
          "Submission unsuccessful. Please try again, and if the problem persists, please reach out to us at info@tactech.tech",
          {
            position: "bottom-center",
            autoClose: 8000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    };

    const renderOptions = (options: string[]) => {
      return options.map((val, index) => (
        <option key={index} value={val}>
          {val}
        </option>
      ));
    };

    return (
      <div className="sm:px-[10vw] xl:px-52 px-10 pt-9" ref={ref}>
        <ToastContainer />
        <hr className="bg-black h-[2px] my-10" />
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-10 py-10">
          <div className="flex flex-col gap-10 text-left">
            <h1 className="md:text-[40px] text-2xl leading-none font-semibold">
              READY TO TURN YOUR IDEA INTO REALITY?
            </h1>
            <p>
              Engage with our experienced tech consultants for a comprehensive
              evaluation and assessment of your idea.
            </p>
            <p>Together, let's shape the future.</p>
          </div>
          <div className="flex flex-col">
            <form onSubmit={handleFormSubmit} action="" className="w-full">
              <input
                type="text"
                required
                value={name}
                className="bg-white py-3 pl-5 w-full border-b-2 text-gray-700 focus:outline-none mb-3"
                placeholder="YOUR NAME *"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <input
                type="email"
                value={email}
                required={phone === ""}
                placeholder="YOUR EMAIL *"
                className="bg-white py-3 pl-5 w-full border-b-2 text-gray-700 focus:outline-none mb-3"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <p className="text-center text-black font-light py-3 pl-5 text-[12px]">
                AND / OR
              </p>
              <input
                type="tel"
                value={phone}
                required={email === ""}
                placeholder="YOUR PHONE (WITH COUNTRY CODE) *"
                className="bg-white py-3 pl-5 w-full border-b-2 text-gray-700 focus:outline-none mb-3"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
              <select
                name=""
                id=""
                required
                value={project}
                className="bg-white py-3 pl-5 w-full border-b-2 text-gray-700 uppercase focus:outline-none mb-3 appearance-none"
                onChange={(e) => {
                  setProject(e.target.value);
                }}
              >
                <option value="" disabled>
                  SELECT PROJECT TYPE *
                </option>
                {renderOptions(projecttypes)}
              </select>
              <select
                name=""
                id=""
                value={country}
                className="bg-white py-3 pl-5 w-full border-b-2 text-gray-700 uppercase focus:outline-none mb-3  appearance-none"
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              >
                <option disabled value="">
                  SELECT COUNTRY
                </option>
                {renderOptions(countries)}
              </select>
              <textarea
                name=""
                id=""
                required
                placeholder="YOUR MESSAGE *"
                value={message}
                className="bg-white py-3 pl-5 w-full border-2 my-3 rounded-xl text-gray-700 focus:outline-none"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              ></textarea>
              {/* <ReCAPTCHA sitekey={siteKey}/> */}
              <div className="flex justify-end">
                <button className="bg-transparent text-[#198754] mt-5  border-[1px] border-[#198754] px-10 py-2 rounded-full hover:bg-[#198754] hover:text-white  transition-colors duration-700 transition-delay-500 ease-in-out">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
);

export default Forms;
